import React, { useState, useEffect } from 'react'
import {
	InputLabel,
	Select,
	MenuItem,
	Button,
	Grid,
	Typography,
} from '@material-ui/core'
import { useForm, FormProvider } from 'react-hook-form'
import FormInput from './FormInput'
import { commerce } from '../../lib/commerce'
import { Link } from 'react-router-dom'
import useStyles from './styles'

export default function AddressForm({ checkoutToken, next }) {
	const [shippingCountries, setShippingCountries] = useState([])
	const [shippingCountry, setShippingCountry] = useState('US')
	const [shippingSubdivisions, setShippingSubdivisions] = useState([])
	const [shippingSubdivision, setShippingSubdivision] = useState('')
	const [shippingOptions, setShippingOptions] = useState([])
	const [shippingOption, setShippingOption] = useState('')

	useEffect(() => {
		fetchShippingCountries(checkoutToken.id)
	}, [])

	useEffect(() => {
		if (shippingCountry) fetchSubdivisions(shippingCountry)
	}, [shippingCountry])

	useEffect(() => {
		if (shippingSubdivision)
			fetchShippingOptions(
				checkoutToken.id,
				shippingCountry,
				shippingSubdivision
			)
	}, [shippingSubdivision])

	const methods = useForm()
	const classes = useStyles()

	const countries = Object.entries(shippingCountries).map(([abv, name]) => ({
		id: abv,
		label: name,
	}))
	const subdivisions = Object.entries(shippingSubdivisions).map(
		([abv, name]) => ({ id: abv, label: name })
	)
	const options = shippingOptions.map((option) => ({
		id: option.id,
		label: `${option.description} - (${option.price.formatted_with_symbol})`,
	}))

	const fetchShippingCountries = async (checkoutTokenId) => {
		const { countries } = await commerce.services.localeListShippingCountries(
			checkoutTokenId
		)

		setShippingCountries(countries)
		setShippingCountry(Object.keys(countries)[2])
	}

	const fetchSubdivisions = async (countryAbv) => {
		const { subdivisions } = await commerce.services.localeListSubdivisions(
			countryAbv
		)

		setShippingSubdivisions(subdivisions)
		setShippingSubdivision(Object.keys(subdivisions)[0])
	}

	const fetchShippingOptions = async (
		checkoutTokenId,
		country,
		region = null
	) => {
		const options = await commerce.checkout.getShippingOptions(
			checkoutTokenId,
			{ country, region }
		)

		setShippingOptions(options)
		setShippingOption(options[0].id)
	}

	return (
		<>
			<Typography variant='h6' gutterBottom>
				Shipping Address
			</Typography>
			<FormProvider {...methods}>
				<form
					onSubmit={methods.handleSubmit((data) =>
						next({
							...data,
							shippingCountry,
							shippingSubdivision,
							shippingOption,
						})
					)}
				>
					<Grid container spacing={3}>
						<FormInput name='firstName' label='First Name' />
						<FormInput name='lastName' label='Last Name' />
						<FormInput name='address1' label='Address' />
						<FormInput name='email' label='Email' />
						<FormInput name='city' label='City' />
						<FormInput name='zip' label='Zip Code' />
						<Grid item xs={12} sm={6}>
							<InputLabel>Shipping Country</InputLabel>
							<Select
								value={shippingCountry}
								fullWidth
								onChange={(e) => setShippingCountry(e.target.value)}
							>
								{countries.map((country) => (
									<MenuItem key={country.id} value={country.id}>
										{country.label}
									</MenuItem>
								))}
							</Select>
						</Grid>
						<Grid item xs={12} sm={6}>
							<InputLabel>State/Region</InputLabel>
							<Select
								value={shippingSubdivision}
								fullWidth
								onChange={(e) => setShippingSubdivision(e.target.value)}
							>
								{subdivisions.map((subdivision) => (
									<MenuItem key={subdivision.id} value={subdivision.id}>
										{subdivision.label}
									</MenuItem>
								))}
							</Select>
						</Grid>
						<Grid item xs={12} sm={6}>
							<InputLabel>Shipping Options</InputLabel>
							<Select
								value={shippingOption}
								fullWidth
								onChange={(e) => setShippingOption(e.target.val)}
							>
								{options.map((option) => (
									<MenuItem key={option.id} value={option.id}>
										{option.label}
									</MenuItem>
								))}
							</Select>
						</Grid>
					</Grid>
					<br />
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Button component={Link} to='/cart' variant='outlined'>
							Go Back
						</Button>
						<Button
							type='submit'
							variant='contained'
							className={classes.buttonAccent}
						>
							Next
						</Button>
					</div>
				</form>
			</FormProvider>
		</>
	)
}
